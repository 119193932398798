<template>
  <div class="_w-max _pc-pad-bottom-105 _mb-pad-bottom-80">
      
    <div class="_pc-pad-x-265 _mb-pad-x-0">
      <div style="background-color: #F7F7F7;" class="_pc-pad-x-45 _mb-pad-x-30 _mb-pad-bottom-65">
        <div class="_flex-col _flex-col-mid _pc-pad-y-60 _mb-pad-y-50" v-for="(item,key) in data" :key="key" v-show="key==index">
          <div class="_flex-col-mid" >
            <div class="_pc-font-28 _b _mb-font-38">{{ item.title }}</div>
            <div class="_pc-mar-top-25 _pc-mar-bottom-50 _mb-mar-bottom-30 _mb-mar-top-20 _flex-row-mid _pc-font-18 _mb-font-24">
              <div class="_grey-99 ">日期：{{ item.add_time}}</div>
              <router-link :to="'/newList'" class="_flex-row-mid _pc-mar-left-40 _mb-mar-left-40">
                <img class="_pc-mar-right-10 _pc-w-15 _mb-w-25 _mb-mar-right-10" src="@/assets/image/fanhui.png" alt="返回.png"><span class="theme-color">返回新闻列表</span>
              </router-link>
            </div>
          </div>

          <div class="_w-max _pc-font-16 _mb-font-28 _mb-grey-99" style="font-family:'Microsoft YaHei';" name="content" v-html="item.content"></div>
        </div>

        <div class="_flex-row-bet-mid _pc-pad-y-20 _border-top _w-max _mb-display-block _pc-font-16 _mb-font-24 theme-color _mb-pad-top-30">
          <div class=" _over-hid bottom-nav _mb-pad-bottom-20" @click="preNews" :title="preNewsTitle">上一篇 : {{preNewsTitle?preNewsTitle:'没有更多了'}}</div>
          <div class=" _over-hid bottom-nav" @click="nextNews" :title="nextNewsTitle">下一篇 : {{nextNewsTitle?nextNewsTitle:'没有更多了'}}</div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PcWebDetaile',

  data() {
    return {
      top_banner:'',
      nextNewsTitle:'',
      preNewsTitle:'',
      data:[],
      index:'',
      page:{
        pageNum:1,
        pageSize:5,//每页条数
        currentPage:1 //页码
      },
      keyword:''
    };
  },
  mounted() {
    this.index = JSON.parse(this.$route.query.index)
    this.page = JSON.parse(this.$route.query.page)
    this.keyword = JSON.parse(this.$route.query.keyword)
    this.get_data()
  },
  created() {

  },
  methods: {
    async get_data(){
      let data = {
        page:this.page.currentPage,
        limit:this.page.pageSize,
        cid:3
      }
      await this.$axios.get(
        'site/get_site_newList',
        {
          params:data
        }
      ).then(res => {
        let list;
        let index = this.index
        let keyword = this.keyword
        if(keyword==1){
        //  热门
          list = res.data.data.all
          for (let i = 0;i<res.data.data.all.length;i++){
            if (list[i].is_hot==1){
              this.data.push(list[i]);
            }
          };
        }else if(keyword==2){
          //banner
          list = res.data.data.all
          for (let i = 0;i<res.data.data.all.length;i++){
            if (list[i].is_banner==1){
              this.data.push(list[i]);
            }
          };
        }else if(keyword==3){
          //普通
         list = res.data.data.list
          this.data = list
        }
        if(this.index==0){
          this.preNewsTitle = ''
        }else{
          this.preNewsTitle = this.data[index-1].title
        }
        if((this.index+1)==list.length){
          this.nextNewsTitle = ''
        }else{
          this.nextNewsTitle = this.data[index+1].title
        }
      })

      await this.$axios.get('site/get_site_comment',{params:data}).then(res => {
        this.top_banner = res.data.data.avaluation_banner
      })
    },
    // 下一篇
    nextNews(){
      if (this.index+1<(this.data.length)){
        this.index=this.index+1;
        this.nextNewsTitle = (this.index+1)<(this.data.length)?this.data[this.index+1].title:''
        this.preNewsTitle = this.data[this.index-1].title
      }else{
        this.nextNewsTitle = ''
      }
    },
    // 上一篇
    preNews(){
      if (this.index-1>=0){
        this.index=this.index-1;
        this.nextNewsTitle = this.data[this.index+1].title
        this.preNewsTitle =  (this.index-1)>=0?this.data[this.index-1].title:''
      }else{
        this.preNewsTitle=''
      }
    }
  },
};
</script>

<style scoped>
  .bottom-nav{
    max-width: 40%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    cursor: pointer;
  }
</style>
